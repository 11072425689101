<template>
  <v-card
    class="elevation-0 mx-auto"
    max-width="1024"
  >
    <HeaderCard>
      <v-icon
        class="mr-1"
        size="medium"
      >
        mdi-disc-player
      </v-icon>
      <b>{{ 'title.newAudios'.translate() }}</b>
    </HeaderCard>

    <v-card-text>
      <v-form
        ref="form"
        autocomplete="off"
      >
        <v-container
          grid-list-xl
          style="padding-top: 0"
        >
          <v-layout
            v-if="isLoading"
            wrap
            align-center
            justify-center
          >
            <v-progress-circular
              :size="55"
              indeterminate
              color="primary"
              class="mx-auto my-7"
            />
          </v-layout>

          <v-layout
            wrap
            style="padding: 16px 0 0"
            v-else
          >
            <v-flex
              xs12
              sm4
              v-if="!audios.length"
            >
              <FieldGrau
                label="Grau do conteúdo"
                v-model="audioGrau"
              />
            </v-flex>

            <v-flex
              xs12
              sm4
              v-if="!audios.length"
            >
              <FieldTipo
                label="Tipo do Áudio"
                v-model="audioTipo"
              />
            </v-flex>

            <v-flex
              xs12
              sm4
              v-if="!audios.length"
            >
              <FieldDate
                label="Data"
                v-model="audioData"
              />
            </v-flex>

            <v-flex
              xs12
              sm4
              v-if="!audios.length"
            >
              <FieldOrador
                clearable
                label="Orador"
                v-model="audioOrador"
              />
            </v-flex>

            <v-flex
              xs12
              sm4
              v-if="!audios.length"
            >
              <FieldOrador
                clearable
                label="Dirigente"
                v-model="audioDirigente"
              />
            </v-flex>

            <v-flex
              xs12
              sm4
              v-if="!audios.length"
            >
              <FieldOrador
                clearable
                label="Autor"
                v-model="audioAutor"
              />
            </v-flex>

            <v-flex
              xs12
              sm8
              v-if="!audios.length"
            >
              <FieldCore
                icon=""
                hide-details
                label="Núcleo"
                clearable
                v-model="audioNucleo"
              />
            </v-flex>

            <v-flex
              xs12
              sm4
              v-if="!audios.length"
            >
              <FieldSessao
                label="Sessão"
                v-model="audioSessao"
              />
            </v-flex>

            <v-flex
              xs12
              sm8
              v-if="!audios.length"
            >
              <FieldWords
                label="Palavras Chaves"
                v-model="audioPalavras"
                :rows="1"
              />
            </v-flex>

            <v-flex
              xs12
              sm4
              v-if="!audios.length"
            >
              <FieldQualidade
                label="Qualidade"
                v-model="audioQualidade"
              />
            </v-flex>

            <v-flex
              xs12
              sm8
              v-if="!audios.length"
            >
              <FieldAlbum
                clearable
                label="Álbum"
                v-model="audioAlbum"
              />
            </v-flex>

            <v-flex
              xs12
              sm4
              v-if="!audios.length"
            >
              <FieldPermissao
                label="Permissão"
                v-model="audioPermissao"
              />
            </v-flex>

            <v-flex
              xs12
              sm8
              v-if="!audios.length"
            >
              <FieldText
                label="Transcrição"
                v-model="audioTranscricao"
                :rows="1"
              />
            </v-flex>

            <v-flex
              xs12
              sm4
              v-if="!audios.length"
            >
              <FieldReview
                label="Revisão"
                v-model="audioReview"
              />
            </v-flex>

            <v-flex
              xs12
              v-if="!audios.length"
            >
              <FieldText
                label="Mestre disse"
                v-model="audioMestreDisse"
                :rows="1"
              />
            </v-flex>

            <template v-for="audio in audios">
              <AudioFormItem
                :key="audio.uuid"
                :error="audio.error"
                :stream="audio.stream"
                :percent="audio.percent"
                :preparing="audio.preparing"
                v-model="audio.metadata"
                @on-remove="removeAudio(audio)"
              />
            </template>
          </v-layout>

          <v-layout
            wrap
            align-center
            justify-center
            v-show="!isLoading"
          >
            <v-flex xs12>
              <div
                ref="dropzone"
                id="dropzone"
                class="dropzone"
                style="margin: 0 auto; border: 1px dashed rgba(15, 7, 7, 0.61); text-align: center; padding: 0"
              >
                <center
                  class="pt-8"
                  v-if="audios.length"
                >
                  Clique aqui para adicionar áudios ou arraste os áudios até aqui!
                </center>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>

    <v-divider />

    <v-card-actions style="padding-top: 0.7em; padding-bottom: 0.7em;">
      <v-layout
        wrap
        justify-space-around
      >
        <v-btn
          color="blue darken-1"
          text
          @click="goBack"
        >
          Voltar
        </v-btn>
        <v-btn
          color="success"
          small
          @click="saveAudios"
          depressed
          :loading="isLoading"
        >
          Salvar dados
        </v-btn>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import FieldText from '@/components/fields/FieldText'
import FieldTipo from '@/components/fields/FieldTipo'
import FieldDate from '@/components/fields/FieldDate'
import FieldGrau from '@/components/fields/FieldGrau'
import FieldCore from '@/components/fields/FieldCore'
import FieldWords from '@/components/fields/FieldWords'
import FieldAlbum from '@/components/fields/FieldAlbum'
import FieldSessao from '@/components/fields/FieldSessao'
import FieldOrador from '@/components/fields/FieldOrador'
import FieldReview from '@/components/fields/FieldReview'
import FieldPermissao from '@/components/fields/FieldPermissao'
import FieldQualidade from '@/components/fields/FieldQualidade'

import { requests } from '@/plugins/Amplify'
import AudioFormItem from './AudioFormItem'
import HeaderCard from '@/components/HeaderCard'
import { formatSecs } from '@/utils'
import Dropzone from 'dropzone'
import * as mm from 'music-metadata-browser'
import 'dropzone/dist/dropzone.css'

Dropzone.autoDiscover = false

export default {
  components: {
    FieldText,
    FieldTipo,
    FieldDate,
    FieldGrau,
    FieldCore,
    FieldWords,
    HeaderCard,
    FieldAlbum,
    FieldOrador,
    FieldReview,
    FieldSessao,
    AudioFormItem,
    FieldQualidade,
    FieldPermissao
  },

  data () {
    return {
      audios: [],
      audioTipo: null,
      audioData: null,
      audioGrau: 2,
      audioAlbum: null,
      audioAutor: null,
      audioNucleo: null,
      audioOrador: null,
      audioDirigente: null,
      audioQualidade: 'B',
      audioPermissao: 'QM',
      audioSessao: null,
      audioPalavras: '',
      audioMestreDisse: '',
      audioTranscricao: '',
      isLoading: false,
      audioReview: 0
    }
  },

  methods: {
    goBack () {
      this.$router.go(-1)
    },

    removeAudio (audio) {
      if (audio.file) this.dropZone.removeFile(audio.file)
      audio.file = null
      const indexAudio = this.audios.indexOf(audio)
      if (indexAudio > -1) this.audios.splice(indexAudio, 1)
    },

    saveAudios () {
      const saveAudio = async () => {
        const audio = this.audios.pop()
        if (!audio) return

        try {
          const { signedPost, metadata } = audio
          const body = { ...metadata, file: signedPost.key }
          await requests.postAudio(body).catch(() => requests.postAudio(body)).catch(() => requests.postAudio(body))
        } catch (error) {
          audio.error = error
          return this.audios.unshift(audio)
        }

        return saveAudio()
      }

      const promises = []
      for (let i = 0; i < 5; i++) promises.push(saveAudio())

      this.isLoading = true
      Promise.all(promises).catch(() => {}).then(() => {
        this.isLoading = false
      })
    },

    setAttrAudio (file, attrs) {
      const indexAudio = this.audios.findIndex(item => item.file === file)
      if (indexAudio === -1) return

      Object.assign(this.audios[indexAudio], attrs)
      this.audios[indexAudio].preparing = !this.audios[indexAudio].signedPost || !this.audios[indexAudio].metadata
      this.audios.splice(indexAudio, 1, this.audios[indexAudio])
    }
  },

  mounted () {
    const vm = this
    if (vm.dropZone) return

    const _URL = window.URL || window.webkitURL

    vm.dropZone = new Dropzone(vm.$refs.dropzone, {
      url: `https://s3.amazonaws.com/${process.env.VUE_APP_BUCKET}`,
      method: 'post',
      timeout: null,
      acceptedFiles: 'audio/*,video/*',
      maxFilesize: 1024 * 1024 * 1024 * 1024,
      uploadMultiple: false,
      parallelUploads: 3,
      autoProcessQueue: true,
      dictDefaultMessage: 'Clique aqui para adicionar áudios ou arraste os áudios até aqui!',
      previewTemplate: '<div></div>',

      accept: async (file, done) => {
        const audio = { file, uuid: file.upload.uuid, preparing: true }
        audio.stream = { url: _URL.createObjectURL(file), name: '', artist: '' }
        vm.isLoading = true

        const isVideo = String(file?.type ?? '').trim().startsWith('video/')
        const isAudio = String(file?.type ?? '').trim().startsWith('audio/')

        if (!isVideo && !isAudio) return done(false)
        const filename = String(file.name || '').trim()
        const fileSplit = filename.split('.')
        const extensao = fileSplit.pop()
        file.extension = extensao

        requests.getAudioUploadLink(file).then(async (signedPost) => {
          let assunto = fileSplit.join('.')
          let albumName, authorName, oradorName, audioType, audioSecs, duracao

          if (isVideo) {
            await new Promise((resolve) => {
              const video = document.createElement('video')
              video.preload = 'metadata'

              video.onloadedmetadata = () => {
                window.URL.revokeObjectURL(video.src)
                duracao = Math.ceil(video.duration)
                audioSecs = formatSecs(duracao)
                video.remove()
                resolve()
              }

              video.src = window.URL.createObjectURL(file)
            })
          }

          if (isAudio) {
            const audioData = await mm.parseBlob(file)
            const { common, format } = audioData
            assunto = common.title
            duracao = Math.ceil(format.duration)
            audioSecs = formatSecs(duracao)

            albumName = String(common.album || '').trim().replace(/,/g, '-')
            audioType = String(common.genre?.shift() || '').trim().toLowerCase()
            oradorName = String(common.artists?.shift() || '').trim().toLowerCase()
            authorName = String(common.composer?.shift() || '').trim().toLowerCase()
          }

          let tipo = vm.audioTipo
          let palavras = String(vm.audioPalavras || '').trim()
          if (albumName) palavras = palavras ? `${palavras},Álbum: ${albumName}` : `Álbum: ${albumName}`

          const album = await getAlbum(albumName, vm.audioAlbum)
          const autor = await getOrador(authorName, vm.audioAutor)
          const orador = await getOrador(oradorName, vm.audioOrador)
          const dirigente = await getOrador(vm.audioDirigente, vm.audioDirigente)
          const qualidade = String(vm.audioQualidade || '').trim()

          if (['oratórias', 'oratorias', 'oratoria', 'oratória'].includes(audioType)) tipo = 1
          if (['histórias', 'historias', 'historia', 'história'].includes(audioType)) tipo = 2
          if (['chamadas', 'chamada'].includes(audioType)) tipo = 3
          if (['sessão', 'sessao'].includes(audioType)) tipo = 4
          if (['músicas', 'musicas', 'musica', 'música'].includes(audioType)) tipo = 5
          if (['explicação', 'explicacao'].includes(audioType)) tipo = 6
          if (['palavra do mestre'].includes(audioType)) tipo = 7

          Object.assign(audio, {
            signedPost,
            percent: 0,
            error: null,

            metadata: {
              resumo: '',

              assunto,
              duracao,
              extensao,
              audioSecs,

              isVideo,
              isAudio,
              filename,

              size: file.size,
              type: file.type,

              grau: vm.audioGrau,
              tipo,
              autor,
              album,
              orador,
              palavras,
              qualidade,
              dirigente,
              data: vm.audioData,
              review: vm.audioReview,
              nucleo: vm.audioNucleo,
              sessao: vm.audioSessao,
              permissao: vm.audioPermissao,
              transcricao: vm.audioTranscricao,
              mestreDisse: vm.audioMestreDisse
            }
          })

          vm.audios.push(audio)
          vm.isLoading = false
          done()
        }).catch((err) => {
          console.log({ err })
          vm.removeAudio(audio)
          vm.isLoading = false
          done(err)
        })
      },

      sending: (file, _, formData) => {
        const { signedPost } = vm.audios.find(item => item.file === file)
        for (const property of Object.keys(signedPost)) formData.append(property, signedPost[property])
      },

      error: (file, error) => {
        vm.setAttrAudio(file, { error: error })
      },

      uploadprogress (file, progress) {
        if (isNaN(progress)) return
        vm.setAttrAudio(file, { percent: `${Math.floor(progress)}%` })
      }
    })
  },

  beforeDestroy () {
    if (this.dropZone) this.dropZone.destroy()
    this.dropZone = null
  }
}

const getOrador = async (name, defaultName) => {
  const orador = name || defaultName
  const isMG = ['m. gabriel', 'mestre gabriel'].includes(orador)
  const isNonato = ['m. nonato', 'mestre nonato', 'nonato'].includes(orador)
  return isMG ? 1 : (isNonato ? 21 : await requests.getOradores({ orador }).then((oradores) => oradores.length === 1 ? oradores.shift().id : defaultName))
}

const getAlbum = async (nome, defaultId) => {
  if (!nome) return defaultId
  return requests.getAlbums({ nome }).then((albums) => albums.length === 1 ? albums.shift().id : defaultId)
}

</script>
